import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Parallax, ParallaxContext, useController } from 'react-scroll-parallax';
// styles
import '../scss/entertainment.scss';
import entertainmentPageMedia from 'src/hooks/entertainmentPageMedia';
import entertainmentPageMobileMedia from 'src/hooks/entertainmentPageMobileMedia';
import useMediaQuery from 'src/hooks/useMediaQuery';
// utils
import handlePageTransition from '../lib/handle-page-transition';
// components
import SEO from '../components/SEO';
import ParallaxImage from '../components/parallax/ParallaxImage';
import SectionTrigger from '../components/SectionTrigger';
import ToutCaption from '../components/ToutCaption';
import BestShapeTout from '../components/home/BestShapeTout';
import GamePlanTout from '../components/home/GamePlanTout';
import McEnroeTout from '../components/home/McEnroeTout';
import BestShapeToutMobile from '../components/home/BestShapeToutMobile';
import GamePlanToutMobile from '../components/home/GamePlanToutMobile';
import McEnroeToutMobile from '../components/home/McEnroeToutMobile';

function EntertainmentPage({ transitionStatus }) {
  let isDesktop = useMediaQuery('(min-width: 1024px)');
  const isServer = typeof window === 'undefined';
  const context = React.useContext(ParallaxContext)

  if (!isServer && context ) {
    const { parallaxController } = useController();
    handlePageTransition(parallaxController, transitionStatus);
  }

  const mobileImages = [];
  const images = [];
  const mobileMedia = entertainmentPageMobileMedia();
  const media = entertainmentPageMedia();

  mobileMedia.allCloudinaryMedia.edges.forEach(item => mobileImages.push(item.node.secure_url));
  media.allCloudinaryMedia.edges.forEach(item => images.push(item.node.secure_url));

  const [
    img01,
    img02,
    img04,
    img05,
    img06,
    img07,
    img08,
    img09,
    img03,
    img10,
    img11,
    img12,
    img13,
  ] = images;

  const [
    img01mobile,
    img02mobile,
    img03mobile,
    img04mobile,
    img05mobile,
    img06mobile,
    img07mobile,
    img08mobile,
    img09mobile,
    img10mobile,
    img11mobile,
    img12mobile,
    img13mobile,
  ] = mobileImages;

  return (
    <div>
      <SEO title="Entertainment | Falkon Content" />
      <div className="ent">
        <SectionTrigger
          className="ent-section ent-section--1 container"
          bgColor="#507d9b"
          textColor="#fff"
        />
        <SectionTrigger
          className="ent-section ent-section--2 container"
          bgColor="#aababf"
          textColor="#fff"
        />
        <SectionTrigger
          className="ent-section ent-section--3 container"
          bgColor="#006699"
          textColor="#fff"
        />


        <SectionTrigger
          className="ent-section ent-section--4 container"
          bgColor="#006699"
          textColor="#fff"
        />
        <SectionTrigger
          className="ent-section ent-section--5 container"
          bgColor="#3399cc"
          textColor="#fff"
        />
        <SectionTrigger
          className="ent-section ent-section--6 container"
          bgColor="#99ccff"
          textColor="#fff"
        />
        <SectionTrigger
          className="ent-section ent-section--7 container"
          bgColor="#b8d8f9"
          textColor="#fff"
        />
        {isDesktop
          ? (
            <div className="ent-container">
                <BestShapeTout
                    bgColor="#507d9b"
                    scrollOffset={[0, 0]}
                    textColor="#fff"
                />
                 <GamePlanTout
                    bgColor="#aababf"
                    scrollOffset={[0, 0]}
                    textColor="#fff"
                />
                <UnbannedBlock images={[img01, img02, img03]} />
                <PromiseLandBlock images={[img04, img05, img06, img07, img08, img09, img10]} />
                <InDevelopmentBlock images={[img11, img12, img13]} />
            </div>
          ) : (
            <div className="ent-container">
                <BestShapeToutMobile
                    bgColor="#507d9b"
                    scrollOffset={[0, 0]}
                    textColor="#fff"
                />
                <GamePlanToutMobile
                    bgColor="#aababf"
                    scrollOffset={[0, 0]}
                    textColor="#fff"
                />
              <UnbannedBlock images={[img01mobile, img02mobile, img03mobile]} />
              <PromiseLandBlock images={[img04mobile, img05mobile, img06mobile, img07mobile, img08mobile, img09mobile, img10mobile]} />
              <InDevelopmentBlock images={[img11mobile, img12mobile, img13mobile]} />
            </div>
          )
        }
      </div>
    </div>
  )
}

const UnbannedBlock = ({ images }) => {
  const [img01, img02, img03] = images;
  return (
    <div className="ent-ub">
      <div className="ent-ub__img ent-ub__img--1">
        <img src={img01} alt="no alt" />
      </div>

      <Parallax
        className="ent-ub__img ent-ub__img--2"
        y={[40, -40]}
      >
        <ParallaxImage src={img02} alt="no alt" />
      </Parallax>

      <div className="ent-ub__img ent-ub__img--3">
        <img src={img03} alt="no alt" />
      </div>

      <ToutCaption
        className="hide-desktop"
        modifier="ent-ub"
        title="Unbanned"
        subtitle="A feature documentary film"
        link="/case-studies/unbanned"
        logo="jordan"
      />

      <ToutCaption
        className="desktop-only"
        modifier="ent-ub"
        title="Unbanned"
        subtitle="A feature documentary film"
        link="/case-studies/unbanned"
      />
    </div>
  );
}

const PromiseLandBlock = ({ images }) => {
  const [
    img04,
    img05,
    img06,
    img07,
    img08,
    img09,
    img10,
  ] = images;
  return (
    <div className="ent-pl">
      <div className="ent-pl__img ent-pl__img--1">
        <img src={img04} alt="no alt" />
      </div>

      <div className="ent-pl__img ent-pl__img--2">
        <img src={img05} alt="no alt" />
      </div>

      <div className="ent-pl__img ent-pl__img--3">
        <img src={img06} alt="no alt" />
      </div>

      <div className="ent-pl__img ent-pl__img--4">
        <img src={img07} alt="no alt" />
      </div>

      <div className="ent-pl__img ent-pl__img--5">
        <img src={img08} alt="no alt" />
      </div>

      <div className="ent-pl__img ent-pl__img--6">
        <img src={img09} alt="no alt" />
      </div>

      <Parallax
        className="ent-pl__img ent-pl__img--7"
        y={[80, -40]}
      >
        <img src={img10} alt="no alt" />
      </Parallax>

      <Parallax
        className="ent-pl__header"
        y={[80, 0]}
      >
        <p className="main-copy ent-pl__header-copy">
          PREMIUM DOCU-SERIES
        </p>
        <h3 className="main-heading ent-pl__header-hdg">
          Promise<span className="outline">land</span>
        </h3>
        <h3 className="main-heading ent-pl__header-hdg">
          Promise<span className="outline">land</span>
        </h3>
        <h3 className="main-heading ent-pl__header-hdg">
          Promise<span className="outline">land</span>
        </h3>
      </Parallax>

      <Parallax
        className="ent-pl__paragraph ent-pl__paragraph--1"
        y={[80, -40]}
      >
        <p className="main-copy">
          This premium 6-part docu-series follows the intimate journey and personal evolution of
          Ja Morant as his dreams of NBA  Superstardom unfold over the course of his rookie year.
        </p>
      </Parallax>

      <div className="ent-pl__paragraph ent-pl__paragraph--2">
        <p className="main-copy">
          Amid the most un- precedented season in NBA History and the most extraordinary events of 2020  - we experience first hand an NBA Rookie’s Hoop Dreams come to life IN REAL TIME.
        </p>
      </div>
    </div>
  );
}

const InDevelopmentBlock = ({ images }) => {
  const [img11, img12, img13] = images;
  return (
    <div className="ent-id">

      <div className="ent-id__img ent-id__img--1">
        <img src={img11} alt="" />
      </div>

      <div className="ent-id__img ent-id__img--2">
        <img src={img12} alt="" />
      </div>

      <div className="ent-id__img ent-id__img--3">
        <img src={img13} alt="" />
      </div>

      <Parallax
        className="ent-id__header ent-id__header--1"
        y={[80, -40]}
      >
        <h3 className="main-heading">
          In Development
        </h3>
      </Parallax>

      <p className="ent-id__subheader main-copy">
        We are in various stages of development on multiple Feature Films, Docu-Series and Scripted Series.
      </p>

      <div className="ent-id__paragraph ent-id__paragraph--1"
      >
        <p className="main-copy">
          To LINK up:
          <br />
          <br />
          Wondwossen Dikran
          <br />
          Head of Development - Falkon Entertainment
          <br />
          <a href="mailto:wondwossen@falkoncontent.com">
            wondwossen@falkoncontent.com
          </a>
        </p>
      </div>
    </div>
  );
}

export default React.memo(EntertainmentPage);